import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { clearHttpError, erroHttpErrorSelector } from '../state';
import DialogFlexSales from './DialogFlexSales';

const AppErrorAlert = () => {
  const error = useSelector(erroHttpErrorSelector);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const handleClose = useCallback(() => dispatch(clearHttpError()), [dispatch]);
  if (!error) return null;

  const code = error?.innerException?.response?.status;
  let title = '';
  switch (code) {
    case 504:
      title = t('errors.awsError.timeoutTitle');
      break;
    case 403:
      title = t('errors.permissions.title');
      break;
    default:
      title = t('errors.awsError.title');
  }

  const message = i18n.exists(`errors.coded.${code}`)
    ? t(`errors.coded.${code}`)
    : t('errors.awsError.description');

  return (
    <DialogFlexSales open onClose={handleClose} aria-describedby="alert-dialog-error-description">
      <DialogTitle id="draggable-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-error-description">{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Ok</Button>
      </DialogActions>
    </DialogFlexSales>
  );
};

export default AppErrorAlert;
