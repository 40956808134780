import { useCallback, useMemo } from 'react';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { AppPermissions, MasterImportExecutionForm } from '../../types';
import {
  FlexSalesForm,
  TextFieldFormInput,
  FlexSalesFormActions,
  CountryMultiSelectFormInput,
} from '../FlexSalesForm';

import routes from '../../config/routes';
import { PermissionsProvider } from '../Permissions';
import { useListener } from '../../hooks';

import { getSchema } from './schema';
import { ActionType } from '../../state/report/types';
import MastersTypesFormInput from './MastersTypesFormInput';

const initialValues: MasterImportExecutionForm = {
  countries: [],
  mastersTypes: '',
  days: 0,
};

const resourcePermissions = [AppPermissions.DASHBOARD_MASTER_IMPORT];

const MasterImportForm = () => {
  const { t } = useTranslation();
  const resolver = useMemo(() => yupResolver(getSchema()), []);
  const formListener = useListener(
    ActionType.mastersImportExecutionRequested,
    ActionType.mastersImportExecutionSuccess,
    ActionType.mastersImportExecutionFailure
  );
  const navigate = useNavigate();
  const handleCancel = useCallback(() => {
    navigate(routes.protectedNestedRoutes.mastersImport.index.path);
  }, [navigate]);

  const onSubmit = async (data: MasterImportExecutionForm) => {
    await formListener(data);
    handleCancel();
  };
  return (
    <PermissionsProvider resourcePermissions={resourcePermissions}>
      <FlexSalesForm
        onValidSubmit={onSubmit}
        shouldUnregister
        resolver={resolver}
        defaultValues={initialValues}
      >
        <Grid container flexDirection="column" rowSpacing={1}>
          <Grid item xs>
            <MastersTypesFormInput name="mastersTypes" fullWidth />
          </Grid>
          <Grid item xs>
            <CountryMultiSelectFormInput name="countries" fullWidth />
          </Grid>
          <Grid item xs>
            <TextFieldFormInput
              name="days"
              variant="standard"
              type="number"
              label={t('masters-import.execution.days')}
              fullWidth
            />
          </Grid>
        </Grid>
        <FlexSalesFormActions
          onCancel={handleCancel}
          cancelLabel={t('navigation.buttons.cancel')}
          submitLabel={t('masters-import.execution.label')}
        />
      </FlexSalesForm>
    </PermissionsProvider>
  );
};

export default MasterImportForm;
