import styled from '@emotion/styled';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { DialogFlexSales, MasterImportForm } from '../components';
import { findMastersTypesRequested } from '../state';

const DialogContentStyled = styled(DialogContent)`
  width: 20em;
`;

const MasterImportNewExecution = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(findMastersTypesRequested());
  }, [dispatch]);
  return (
    <DialogFlexSales open maxWidth="sm">
      <DialogTitle>{t('masters-import.title')}</DialogTitle>
      <DialogContentStyled>
        <MasterImportForm />
      </DialogContentStyled>
    </DialogFlexSales>
  );
};

export default MasterImportNewExecution;
