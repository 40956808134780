import { useTranslation } from 'react-i18next';
import { useCountriesSelect } from '../../hooks';
import { Country } from '../../types';
import MultiSelectFormInput, { MultiSelectFormInputProps } from './MultiSelectFormInput';

export type CountryMultiSelectFormInputProps = Omit<
  MultiSelectFormInputProps<Country>,
  'options' | 'value' | 'label'
> & {
  propId?: keyof Country;
};

const isOptionEqualToValue = (store1: Country, store2: Country) =>
  store1.countryId === store2.countryId;

const CountryMultiSelectFormInput = ({
  name,
  propId,
  ...rest
}: CountryMultiSelectFormInputProps) => {
  const { t } = useTranslation();
  const { options, loading, disabled } = useCountriesSelect();

  return (
    <MultiSelectFormInput<Country>
      {...rest}
      name={name}
      label={t('toolbar.labels.country')}
      getOptionLabel="countryCode"
      options={options}
      disabled={disabled}
      loading={loading}
      isOptionEqualToValue={isOptionEqualToValue}
    />
  );
};

export default CountryMultiSelectFormInput;
