import { eachDayOfInterval, format } from 'date-fns';
import {
  closingSort,
  getDateValue,
  getValidEndDate,
  getValidStartDate,
  remoteRequestFailure,
  remoteRequestInitialState,
  remoteRequestLoading,
  remoteRequestSuccess,
  remoteResourceInitialState,
  remoteResourceLoadFailure,
  remoteResourceLoadSuccess,
  remoteResourceLoading,
} from '../../helpers';
import {
  Closing,
  ClosingDetailGroup,
  ClosingViewMode,
  RemoteResource,
  ReportFilter,
  PendinInterfacesViewMode,
} from '../../types';
import { ActionType, Actions, State } from './types';

const getFilterApplied = (state: State, key: keyof ReportFilter, value: unknown) => {
  if (!value) {
    return state.filterApplied.filter(i => i !== key);
  }
  if (Array.isArray(value) && value.length === 0) {
    return state.filterApplied.filter(i => i !== key);
  }
  if (state.filterApplied.includes(key)) {
    return state.filterApplied;
  }
  return [...state.filterApplied, key];
};

const generateConsolidatedClosingDetail = (
  detailToGroup: Closing[],
  otherDetail: RemoteResource<Closing[]>
) => {
  if (otherDetail.loaded === true) {
    otherDetail.data.forEach(secondaryDetail => {
      const foundRegistry = detailToGroup.find(
        mainDetail =>
          secondaryDetail.country === mainDetail.country &&
          secondaryDetail.store === mainDetail.store &&
          secondaryDetail.date.toISOString() === mainDetail.date.toISOString()
      );
      if (foundRegistry) {
        secondaryDetail.cause.forEach(cause => {
          if (!foundRegistry.cause.includes(cause)) {
            foundRegistry.cause.push(cause);
          }
        });
      } else {
        detailToGroup.push(secondaryDetail);
      }
    });
    detailToGroup.sort((a, b) => a.date.getTime() - b.date.getTime());
    return detailToGroup;
  }
  return [];
};

const getClosingCurrent = (
  detailToGroup: Closing[],
  startDate: Date | null,
  endDate: Date | null,
  viewMode: ClosingViewMode,
  otherDetail: RemoteResource<Closing[]>
) => {
  const detail =
    viewMode === ClosingViewMode.CONSOLIDATED
      ? generateConsolidatedClosingDetail(detailToGroup, otherDetail)
      : detailToGroup.sort((a, b) => a.date.getTime() - b.date.getTime());

  const dates = eachDayOfInterval({
    start: startDate as Date,
    end: endDate as Date,
  });

  const dataGroup: Record<string, ClosingDetailGroup> = {};
  detail.forEach(i => {
    i.cause.forEach(cause => {
      const key = `${cause}${i.country}${i.store}`;
      if (!(key in dataGroup)) {
        dataGroup[key] = {
          country: i.country,
          cause,
          store: i.store,
          federativeEntity: i.federativeEntity,
          franchiseType: i.franchiseType,
          quantity: 0,
          dates: [],
        };
      }
      dataGroup[key].dates.push(i.date);
      dataGroup[key].quantity += 1;
    });
  });

  const missingClosingDetailGroup = Object.values(dataGroup);

  let index = 0;
  dates.forEach(date => {
    missingClosingDetailGroup.forEach(i => {
      const date1 =
        typeof i.dates[index] !== 'undefined' ? format(i.dates[index], 'yyyy-MM-dd') : undefined;
      const date2 = format(date, 'yyyy-MM-dd');
      if (date1 !== date2) {
        i.dates.splice(index, 0, undefined);
      }
    });
    index += 1;
  });

  missingClosingDetailGroup.forEach(i => i.dates.reverse());

  return missingClosingDetailGroup.sort(closingSort);
};

const initialState: State = {
  filters: {
    country: null,
    countryCode: '',
    stores: [],
    pos: [],
    salesTypes: [],
    paymentTypes: [],
    hourRange: [],
    timeBand: [],
    segments: [],
    startDate: getDateValue(null),
    endDate: getDateValue(null),
    startBusinessDate: getDateValue(null),
    endBusinessDate: getDateValue(null),
    startOperationDate: getDateValue(null),
    endOperationDate: getDateValue(null),
    startAccountingDate: getDateValue(null),
    endAccountingDate: getDateValue(null),
    groupBy: ['countryAcronym', 'storeAcronym', 'accountingDate'],
    discountsJoin: [],
    reliefsGroupBy: ['countryAcronym'],
    tenderGroupBy: ['countryAcronym', 'tenderName'],
    cancellationsGroupBy: ['countryAcronym'],
    salesProductGroupBy: ['countryAcronym', 'storeAcronym', 'businessDate'],
    thirdPartySalesByItemsGroupBy: ['countryAcronym', 'storeAcronym', 'plu'],
    operationType: [],
    plu: [],
    pod: [],
    saleDiscount: [],
    itemDiscount: [],
    differenceType: '',
    accountingEntriesFields: false,
  },
  filterApplied: [],
  opStructuresFilters: {
    agreement: [],
    regionalMgmt: [],
    region: [],
    management: [],
    supervision: [],
    company: [],
  },
  mastersImportFilters: {
    countries: [],
    startImportDate: getDateValue(null),
    endImportDate: getDateValue(null),
    state: [],
  },
  closingFilters: {
    countries: [],
    stores: [],
    startBusinessDate: getDateValue(null),
    endBusinessDate: getDateValue(null),
    viewMode: ClosingViewMode.CONSOLIDATED,
    franchiseTypes: [],
    federativeEntities: [],
    causeTypes: [],
  },
  posOptions: remoteResourceInitialState([]),
  salesTypesOptions: remoteResourceInitialState([]),
  sales: remoteResourceInitialState([]),
  gcs: remoteResourceInitialState([]),
  gcsPaymentTypes: remoteResourceInitialState([]),
  tender: remoteResourceInitialState([]),
  operationTypeOptions: remoteResourceInitialState([]),
  salesByProduct: remoteResourceInitialState([]),
  gcsByProduct: remoteResourceInitialState([]),
  thirdPartySalesByItems: remoteResourceInitialState([]),
  causeTypesOptions: remoteResourceInitialState([]),
  closing: {
    sales: remoteResourceInitialState([]),
    collections: remoteResourceInitialState([]),
    current: [],
  },
  missingClosingCauses: {
    salesCauses: remoteResourceInitialState({} as never),
    collectionsCauses: remoteResourceInitialState({} as never),
  },
  causesFilters: {
    country: '',
    store: '',
    date: getDateValue(null),
    cause: '',
  },
  missingClosingTotal: {
    filteredStores: [],
    missingClosingTotal: remoteResourceInitialState(null),
  },
  hourRangeOptions: remoteResourceInitialState([]),
  timeBandOptions: remoteResourceInitialState([]),
  reliefs: remoteResourceInitialState([]),
  paymentTypeOptions: remoteResourceInitialState([]),
  cancellations: remoteResourceInitialState([]),
  saleDiscountOptions: remoteResourceInitialState([]),
  itemDiscountOptions: remoteResourceInitialState([]),
  pettyCashDifference: remoteResourceInitialState([]),
  PettyCashDifferenceByPaymentType: remoteResourceInitialState([]),
  salesJournal: {
    gcs: remoteResourceInitialState([]),
    ncs: remoteResourceInitialState([]),
    fcs: remoteResourceInitialState([]),
    thirdparty: remoteResourceInitialState([]),
  },
  mastersImport: remoteResourceInitialState([]),
  mastersTypes: remoteResourceInitialState([]),
  mastersImportExecution: remoteRequestInitialState,
  pendingInterfacesFilter: {
    country: '',
    stores: [],
    startBusinessDate: getDateValue(null),
    endBusinessDate: getDateValue(null),
  },
  pendingInterfacesState: {
    sales: remoteResourceInitialState([]),
  },
  pendingInterfaces: remoteResourceInitialState([]),
};

const reducer = (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case ActionType.changeFilterCountry:
      return {
        ...state,
        posOptions: remoteResourceInitialState([]),
        salesTypesOptions: remoteResourceInitialState([]),
        timeBandOptions: remoteResourceInitialState([]),
        paymentTypeOptions: remoteResourceInitialState([]),
        sales: remoteResourceInitialState([]),
        gcs: remoteResourceInitialState([]),
        gcsPaymentTypes: remoteResourceInitialState([]),
        tender: remoteResourceInitialState([]),
        reliefs: remoteResourceInitialState([]),
        cancellations: remoteResourceInitialState([]),
        salesByProduct: remoteResourceInitialState([]),
        gcsByProduct: remoteResourceInitialState([]),
        thirdPartySalesByItems: remoteResourceInitialState([]),
        filters: {
          ...state.filters,
          ...{
            countryCode: action.countryCode,
            stores: [],
            pos: [],
            salesTypes: [],
            hourRange: [],
            timeBand: [],
            segments: [],
            paymentTypes: [],
            pod: [],
          },
        },
        filterApplied: getFilterApplied(state, 'countryCode', action.countryCode),
        opStructuresFilters: {
          agreement: [],
          regionalMgmt: [],
          region: [],
          management: [],
          supervision: [],
          company: [],
        },
      };
    case ActionType.changeFilterStore:
      return {
        ...state,
        posOptions: remoteResourceInitialState([]),
        sales: remoteResourceInitialState([]),
        gcs: remoteResourceInitialState([]),
        gcsPaymentTypes: remoteResourceInitialState([]),
        tender: remoteResourceInitialState([]),
        reliefs: remoteResourceInitialState([]),
        cancellations: remoteResourceInitialState([]),
        salesByProduct: remoteResourceInitialState([]),
        gcsByProduct: remoteResourceInitialState([]),
        thirdPartySalesByItems: remoteResourceInitialState([]),
        filters: {
          ...state.filters,
          ...{ stores: action.stores, pos: [] },
        },
        filterApplied: getFilterApplied(state, 'stores', action.stores),
      };
    case ActionType.changeFilterPos:
      return {
        ...state,
        sales: remoteResourceInitialState([]),
        gcs: remoteResourceInitialState([]),
        gcsPaymentTypes: remoteResourceInitialState([]),
        tender: remoteResourceInitialState([]),
        salesByProduct: remoteResourceInitialState([]),
        gcsByProduct: remoteResourceInitialState([]),
        filters: {
          ...state.filters,
          ...{ pos: action.pos },
        },
        filterApplied: getFilterApplied(state, 'pos', action.pos),
      };
    case ActionType.changeFilterSalesTypes:
      return {
        ...state,
        sales: remoteResourceInitialState([]),
        tender: remoteResourceInitialState([]),
        salesByProduct: remoteResourceInitialState([]),
        gcsByProduct: remoteResourceInitialState([]),
        filters: {
          ...state.filters,
          ...{ salesTypes: action.salesTypes },
        },
        filterApplied: getFilterApplied(state, 'salesTypes', action.salesTypes),
      };
    case ActionType.changeFilterPaymentTypes:
      return {
        ...state,
        tender: remoteResourceInitialState([]),
        gcsPaymentTypes: remoteResourceInitialState([]),
        filters: {
          ...state.filters,
          ...{ paymentTypes: action.paymentTypes },
        },
        filterApplied: getFilterApplied(state, 'paymentTypes', action.paymentTypes),
      };
    case ActionType.changeFilterOperationType:
      return {
        ...state,
        tender: remoteResourceInitialState([]),
        gcsPaymentTypes: remoteResourceInitialState([]),
        filters: {
          ...state.filters,
          ...{ operationType: action.operationType },
        },
        filterApplied: getFilterApplied(state, 'operationType', action.operationType),
      };
    case ActionType.changeFilterTenderName:
      return {
        ...state,
        tender: remoteResourceInitialState([]),
        gcsPaymentTypes: remoteResourceInitialState([]),
        filters: {
          ...state.filters,
          ...{ tenderName: action.tenderName },
        },
      };
    case ActionType.changeFilterHourRange:
      return {
        ...state,
        sales: remoteResourceInitialState([]),
        tender: remoteResourceInitialState([]),
        gcs: remoteResourceInitialState([]),
        gcsPaymentTypes: remoteResourceInitialState([]),
        salesByProduct: remoteResourceInitialState([]),
        gcsByProduct: remoteResourceInitialState([]),
        filters: {
          ...state.filters,
          ...{ hourRange: action.hourRange, timeBand: [] },
        },
        filterApplied: getFilterApplied(state, 'hourRange', action.hourRange),
      };
    case ActionType.changeFilterSaleDiscount:
      return {
        ...state,
        gcs: remoteResourceInitialState([]),
        gcsByProduct: remoteResourceInitialState([]),
        filters: {
          ...state.filters,
          ...{ saleDiscount: action.discount },
        },
      };
    case ActionType.changeFilterItemDiscount:
      return {
        ...state,
        gcs: remoteResourceInitialState([]),
        gcsByProduct: remoteResourceInitialState([]),
        filters: {
          ...state.filters,
          ...{ itemDiscount: action.discount },
        },
      };
    case ActionType.changeFilterDifferenceType:
      return {
        ...state,
        pettyCashDifference: remoteResourceInitialState([]),
        PettyCashDifferenceByPaymentType: remoteResourceInitialState([]),
        filters: {
          ...state.filters,
          ...{ differenceType: action.differenceType },
        },
      };
    case ActionType.changeFilterPlu:
      return {
        ...state,
        salesByProduct: remoteResourceInitialState([]),
        gcsByProduct: remoteResourceInitialState([]),
        thirdPartySalesByItems: remoteResourceInitialState([]),
        filters: {
          ...state.filters,
          ...{ plu: action.plu },
        },
      };
    case ActionType.changeFilterStoreMotherChild:
      return {
        ...state,
        salesByProduct: remoteResourceInitialState([]),
        gcsByProduct: remoteResourceInitialState([]),
        thirdPartySalesByItems: remoteResourceInitialState([]),
        filters: {
          ...state.filters,
          ...{ pod: action.pod },
        },
      };
    case ActionType.changeFilterTimeBand:
      return {
        ...state,
        sales: remoteResourceInitialState([]),
        tender: remoteResourceInitialState([]),
        gcs: remoteResourceInitialState([]),
        gcsPaymentTypes: remoteResourceInitialState([]),
        filters: {
          ...state.filters,
          ...{ timeBand: action.timeBand, hourRange: [] },
        },
        filterApplied: getFilterApplied(state, 'timeBand', action.timeBand),
      };
    case ActionType.changeFilterSegments:
      return {
        ...state,
        sales: remoteResourceInitialState([]),
        tender: remoteResourceInitialState([]),
        gcs: remoteResourceInitialState([]),
        gcsPaymentTypes: remoteResourceInitialState([]),
        filters: {
          ...state.filters,
          ...{ segments: action.segments },
        },
        filterApplied: getFilterApplied(state, 'segments', action.segments),
      };
    case ActionType.changeFilterStartBusinessDate:
      return {
        ...state,
        sales: remoteResourceInitialState([]),
        missingClosingTotal: {
          ...state.missingClosingTotal,
          missingClosingTotal: remoteResourceInitialState(null),
        },
        gcs: remoteResourceInitialState([]),
        gcsPaymentTypes: remoteResourceInitialState([]),
        tender: remoteResourceInitialState([]),
        reliefs: remoteResourceInitialState([]),
        filters: {
          ...state.filters,
          ...{
            startBusinessDate: getDateValue(action.date),
            endBusinessDate: getDateValue(
              getValidEndDate(action.date, state.filters.endBusinessDate)
            ),
          },
        },
        closing: {
          ...state.closing,
          ...{
            sales: remoteResourceInitialState([]),
            collections: remoteResourceInitialState([]),
            current: [],
          },
        },
        filterApplied: getFilterApplied(state, 'startBusinessDate', action.date),
      };
    case ActionType.changeFilterEndBusinessDate:
      return {
        ...state,
        sales: remoteResourceInitialState([]),
        missingClosingTotal: {
          ...state.missingClosingTotal,
          missingClosingTotal: remoteResourceInitialState(null),
        },
        gcs: remoteResourceInitialState([]),
        gcsPaymentTypes: remoteResourceInitialState([]),
        tender: remoteResourceInitialState([]),
        reliefs: remoteResourceInitialState([]),
        filters: {
          ...state.filters,
          ...{
            endBusinessDate: getDateValue(action.date),
            startBusinessDate: getDateValue(
              getValidStartDate(state.filters.startBusinessDate, action.date)
            ),
          },
        },
        closing: {
          ...state.closing,
          ...{
            sales: remoteResourceInitialState([]),
            collections: remoteResourceInitialState([]),
            current: [],
          },
        },
        filterApplied: getFilterApplied(state, 'endBusinessDate', action.date),
      };
    case ActionType.changeFilterStartOperationDate:
      return {
        ...state,
        sales: remoteResourceInitialState([]),
        missingClosingTotal: {
          ...state.missingClosingTotal,
          missingClosingTotal: remoteResourceInitialState(null),
        },
        gcs: remoteResourceInitialState([]),
        gcsPaymentTypes: remoteResourceInitialState([]),
        tender: remoteResourceInitialState([]),
        filters: {
          ...state.filters,
          ...{
            startOperationDate: getDateValue(action.date),
            endOperationDate: getDateValue(
              getValidEndDate(action.date, state.filters.endOperationDate)
            ),
          },
        },
        filterApplied: getFilterApplied(state, 'startOperationDate', action.date),
      };
    case ActionType.changeFilterEndOperationDate:
      return {
        ...state,
        sales: remoteResourceInitialState([]),
        missingClosingTotal: {
          ...state.missingClosingTotal,
          missingClosingTotal: remoteResourceInitialState(null),
        },
        gcs: remoteResourceInitialState([]),
        gcsPaymentTypes: remoteResourceInitialState([]),
        tender: remoteResourceInitialState([]),
        filters: {
          ...state.filters,
          ...{
            endOperationDate: getDateValue(action.date),
            startOperationDate: getDateValue(
              getValidStartDate(state.filters.startOperationDate, action.date)
            ),
          },
        },
        filterApplied: getFilterApplied(state, 'endOperationDate', action.date),
      };
    case ActionType.changeFilterStartAccountingDate:
      return {
        ...state,
        sales: remoteResourceInitialState([]),
        missingClosingTotal: {
          ...state.missingClosingTotal,
          missingClosingTotal: remoteResourceInitialState(null),
        },
        gcs: remoteResourceInitialState([]),
        gcsPaymentTypes: remoteResourceInitialState([]),
        tender: remoteResourceInitialState([]),
        cancellations: remoteResourceInitialState([]),
        thirdPartySalesByItems: remoteResourceInitialState([]),
        filters: {
          ...state.filters,
          ...{
            startAccountingDate: getDateValue(action.date),
            endAccountingDate: getDateValue(
              getValidEndDate(action.date, state.filters.endAccountingDate)
            ),
          },
        },
        filterApplied: getFilterApplied(state, 'startAccountingDate', action.date),
      };
    case ActionType.changeFilterEndAccountingDate:
      return {
        ...state,
        sales: remoteResourceInitialState([]),
        missingClosingTotal: {
          ...state.missingClosingTotal,
          missingClosingTotal: remoteResourceInitialState(null),
        },
        gcs: remoteResourceInitialState([]),
        gcsPaymentTypes: remoteResourceInitialState([]),
        tender: remoteResourceInitialState([]),
        cancellations: remoteResourceInitialState([]),
        thirdPartySalesByItems: remoteResourceInitialState([]),
        filters: {
          ...state.filters,
          ...{
            endAccountingDate: getDateValue(action.date),
            startAccountingDate: getDateValue(
              getValidStartDate(state.filters.startAccountingDate, action.date)
            ),
          },
        },
        filterApplied: getFilterApplied(state, 'endAccountingDate', action.date),
      };
    case ActionType.changeFilterGroupBy:
      return {
        ...state,
        sales:
          state.filters.groupBy === action.groupBy ? state.sales : remoteResourceInitialState([]),
        filters: {
          ...state.filters,
          ...{ groupBy: action.groupBy },
        },
      };
    case ActionType.changeFindThirdPartySalesByItemsFilterGroupBy:
      return {
        ...state,
        thirdPartySalesByItems:
          state.filters.thirdPartySalesByItemsGroupBy === action.groupBy
            ? state.thirdPartySalesByItems
            : remoteResourceInitialState([]),
        filters: {
          ...state.filters,
          ...{ thirdPartySalesByItemsGroupBy: action.groupBy },
        },
      };
    case ActionType.changeFilterTenderGroupBy:
      return {
        ...state,
        tender:
          state.filters.tenderGroupBy === action.groupBy
            ? state.tender
            : remoteResourceInitialState([]),
        filters: {
          ...state.filters,
          ...{ tenderGroupBy: action.groupBy },
        },
      };
    case ActionType.changeReliefsGroupBy:
      return {
        ...state,
        reliefs:
          state.filters.reliefsGroupBy === action.groupBy
            ? state.reliefs
            : remoteResourceInitialState([]),
        filters: {
          ...state.filters,
          ...{ reliefsGroupBy: action.groupBy },
        },
      };
    case ActionType.changeCancellationsGroupBy:
      return {
        ...state,
        cancellations:
          state.filters.cancellationsGroupBy === action.groupBy
            ? state.cancellations
            : remoteResourceInitialState([]),
        filters: {
          ...state.filters,
          ...{ cancellationsGroupBy: action.groupBy },
        },
      };
    case ActionType.changeSaleProductFilterGroupBy:
      return {
        ...state,
        salesByProduct:
          state.filters.salesProductGroupBy === action.groupBy
            ? state.salesByProduct
            : remoteResourceInitialState([]),
        filters: {
          ...state.filters,
          ...{ salesProductGroupBy: action.groupBy },
        },
      };
    case ActionType.changeFilterDiscountsJoin:
      return {
        ...state,
        gcsByProduct:
          state.filters.discountsJoin === action.discountsJoin
            ? state.gcsByProduct
            : remoteResourceInitialState([]),
        filters: {
          ...state.filters,
          discountsJoin: action.discountsJoin,
        },
      };
    case ActionType.findPosRequested:
      return {
        ...state,
        posOptions: remoteResourceLoading([]),
      };
    case ActionType.findPosSuccess:
      return {
        ...state,
        posOptions: remoteResourceLoadSuccess(action.posOptions),
      };
    case ActionType.findPosFailure:
      return {
        ...state,
        posOptions: remoteResourceLoadFailure([], action.error),
      };
    case ActionType.findSaleDiscountsRequested:
      return {
        ...state,
        saleDiscountOptions: remoteResourceLoading([]),
      };
    case ActionType.findSaleDiscountsSuccess:
      return {
        ...state,
        saleDiscountOptions: remoteResourceLoadSuccess(action.discountOptions),
      };
    case ActionType.findSaleDiscountsFailure:
      return {
        ...state,
        saleDiscountOptions: remoteResourceLoadFailure([], action.error),
      };
    case ActionType.findItemDiscountsRequested:
      return {
        ...state,
        itemDiscountOptions: remoteResourceLoading([]),
      };
    case ActionType.findItemDiscountsSuccess:
      return {
        ...state,
        itemDiscountOptions: remoteResourceLoadSuccess(action.discountOptions),
      };
    case ActionType.findItemDiscountsFailure:
      return {
        ...state,
        itemDiscountOptions: remoteResourceLoadFailure([], action.error),
      };
    case ActionType.findSalesTypesRequested:
      return {
        ...state,
        salesTypesOptions: remoteResourceLoading([]),
      };
    case ActionType.findSalesTypesSuccess:
      return {
        ...state,
        salesTypesOptions: remoteResourceLoadSuccess(action.salesTypesOptions),
      };
    case ActionType.findSalesTypesFailure:
      return {
        ...state,
        salesTypesOptions: remoteResourceLoadFailure([], action.error),
      };
    case ActionType.findCauseTypesRequested:
      return {
        ...state,
        causeTypesOptions: remoteResourceLoading([]),
      };
    case ActionType.findCauseTypesSuccess:
      return {
        ...state,
        causeTypesOptions: remoteResourceLoadSuccess(action.causeTypesOptions),
      };
    case ActionType.findCauseTypesFailure:
      return {
        ...state,
        causeTypesOptions: remoteResourceLoadFailure([], action.error),
      };
    case ActionType.findOperationTypesRequested:
      return {
        ...state,
        operationTypeOptions: remoteResourceLoading([]),
      };
    case ActionType.findOperationTypesSuccess:
      return {
        ...state,
        operationTypeOptions: remoteResourceLoadSuccess(action.operationTypes),
      };
    case ActionType.findOperationTypesFailure:
      return {
        ...state,
        operationTypeOptions: remoteResourceLoadFailure([], action.error),
      };
    case ActionType.findPaymentTypeRequested:
      return {
        ...state,
        paymentTypeOptions: remoteResourceLoading([]),
      };
    case ActionType.findPaymentTypeSuccess:
      return {
        ...state,
        paymentTypeOptions: remoteResourceLoadSuccess(action.paymentType),
      };
    case ActionType.findPaymentTypeFailure:
      return {
        ...state,
        paymentTypeOptions: remoteResourceLoadFailure([], action.error),
      };
    case ActionType.findHourRangeRequested:
      return {
        ...state,
        hourRangeOptions: remoteResourceLoading([]),
      };
    case ActionType.findHourRangeSuccess:
      return {
        ...state,
        hourRangeOptions: remoteResourceLoadSuccess(action.hourRange),
      };
    case ActionType.findHourRangeFailure:
      return {
        ...state,
        hourRangeOptions: remoteResourceLoadFailure([], action.error),
      };
    case ActionType.findTimeBandRequested:
      return {
        ...state,
        timeBandOptions: remoteResourceLoading([]),
      };
    case ActionType.findTimeBandSuccess:
      return {
        ...state,
        timeBandOptions: remoteResourceLoadSuccess(action.timeBand),
      };
    case ActionType.findTimeBandFailure:
      return {
        ...state,
        timeBandOptions: remoteResourceLoadFailure([], action.error),
      };
    case ActionType.findSalesRequested:
      return {
        ...state,
        sales: remoteResourceLoading([]),
      };
    case ActionType.findSalesSuccess:
      return {
        ...state,
        sales: remoteResourceLoadSuccess(action.sales),
      };
    case ActionType.findSalesFailure:
      return {
        ...state,
        sales: remoteResourceLoadFailure([], action.error),
      };
    case ActionType.findReportTenderRequested:
      return {
        ...state,
        tender: remoteResourceLoading([]),
      };
    case ActionType.findReportTenderSuccess:
      return {
        ...state,
        tender: remoteResourceLoadSuccess(action.reportTender),
      };
    case ActionType.findReportTenderFailure:
      return {
        ...state,
        tender: remoteResourceLoadFailure([], action.error),
      };
    case ActionType.findGcsRequested:
      return {
        ...state,
        gcs: remoteResourceLoading([]),
      };
    case ActionType.findGcsSuccess:
      return {
        ...state,
        gcs: remoteResourceLoadSuccess(action.gcs),
      };
    case ActionType.findGcsFailure:
      return {
        ...state,
        gcs: remoteResourceLoadFailure([], action.error),
      };
    case ActionType.findThirdPartySalesByItemsRequested:
      return {
        ...state,
        thirdPartySalesByItems: remoteResourceLoading([]),
      };
    case ActionType.findThirdPartySalesByItemsSuccess:
      return {
        ...state,
        thirdPartySalesByItems: remoteResourceLoadSuccess(action.thirdPartySalesByItems),
      };
    case ActionType.findThirdPartySalesByItemsFailure:
      return {
        ...state,
        thirdPartySalesByItems: remoteResourceLoadFailure([], action.error),
      };
    case ActionType.findGcsByPaymentTypesRequested:
      return {
        ...state,
        gcsPaymentTypes: remoteResourceLoading([]),
      };
    case ActionType.findGcsByPaymentTypesSuccess:
      return {
        ...state,
        gcsPaymentTypes: remoteResourceLoadSuccess(action.gcsPaymentTypes),
      };
    case ActionType.findGcsByPaymentTypesFailure:
      return {
        ...state,
        gcsPaymentTypes: remoteResourceLoadFailure([], action.error),
      };
    case ActionType.findClosingSalesRequested:
      return {
        ...state,
        closing: {
          ...state.closing,
          ...{ sales: remoteResourceLoading([]), current: [] },
        },
      };
    case ActionType.findClosingSalesSuccess:
      return {
        ...state,
        closing: {
          ...state.closing,
          ...{
            sales: remoteResourceLoadSuccess(action.closing),
            current: getClosingCurrent(
              action.closing,
              action.startDate,
              action.endDate,
              state.closingFilters.viewMode,
              state.closing.collections
            ),
          },
        },
      };
    case ActionType.findClosingSalesFailure:
      return {
        ...state,
        closing: {
          ...state.closing,
          ...{ sales: remoteResourceLoadFailure([], action.error), current: [] },
        },
      };
    case ActionType.findClosingCollectionsRequested:
      return {
        ...state,
        closing: {
          ...state.closing,
          ...{ collections: remoteResourceLoading([]), current: [] },
        },
      };
    case ActionType.findClosingCollectionsSuccess:
      return {
        ...state,
        closing: {
          ...state.closing,
          ...{
            collections: remoteResourceLoadSuccess(action.closing),
            current: getClosingCurrent(
              action.closing,
              action.startDate,
              action.endDate,
              state.closingFilters.viewMode,
              state.closing.sales
            ),
          },
        },
      };
    case ActionType.findClosingCollectionsFailure:
      return {
        ...state,
        closing: {
          ...state.closing,
          ...{ collections: remoteResourceLoadFailure([], action.error), current: [] },
        },
      };
    case ActionType.findMissingClosingSalesCausesRequested:
      return {
        ...state,
        missingClosingCauses: {
          ...state.missingClosingCauses,
          salesCauses: remoteResourceLoading({} as never),
        },
      };
    case ActionType.findMissingClosingSalesCausesSuccess:
      return {
        ...state,
        missingClosingCauses: {
          ...state.missingClosingCauses,
          salesCauses: remoteResourceLoadSuccess(action.missingClosingCauses),
        },
      };
    case ActionType.findMissingClosingSalesCausesFailure:
      return {
        ...state,
        missingClosingCauses: {
          ...state.missingClosingCauses,
          salesCauses: remoteResourceLoadFailure({} as never, action.error),
        },
      };
    case ActionType.findMissingClosingCollectionsCausesRequested:
      return {
        ...state,
        missingClosingCauses: {
          ...state.missingClosingCauses,
          collectionsCauses: remoteResourceLoading({} as never),
        },
      };
    case ActionType.findMissingClosingCollectionsCausesSuccess:
      return {
        ...state,
        missingClosingCauses: {
          ...state.missingClosingCauses,
          collectionsCauses: remoteResourceLoadSuccess(action.missingClosingCauses),
        },
      };
    case ActionType.findMissingClosingCollectionsCausesFailure:
      return {
        ...state,
        missingClosingCauses: {
          ...state.missingClosingCauses,
          collectionsCauses: remoteResourceLoadFailure({} as never, action.error),
        },
      };
    case ActionType.changeCausesFilterCountry:
      return {
        ...state,
        causesFilters: {
          ...state.causesFilters,
          ...{
            country: action.country,
            store: '',
            date: getDateValue(null),
            cause: '',
          },
        },
      };
    case ActionType.changeCausesFilterStore:
      return {
        ...state,
        causesFilters: {
          ...state.causesFilters,
          ...{
            store: action.store,
          },
        },
      };
    case ActionType.changeCausesFilterDate:
      return {
        ...state,
        causesFilters: {
          ...state.causesFilters,
          ...{
            date: getDateValue(action.date),
          },
        },
      };
    case ActionType.changeCausesFilterCause:
      return {
        ...state,
        causesFilters: {
          ...state.causesFilters,
          ...{
            cause: action.cause,
          },
        },
      };
    case ActionType.changeOpStrucuresFilterAgreement:
      return {
        ...state,
        sales: remoteResourceInitialState([]),
        gcs: remoteResourceInitialState([]),
        gcsPaymentTypes: remoteResourceInitialState([]),
        opStructuresFilters: {
          ...state.opStructuresFilters,
          ...{ agreement: action.agreement },
        },
      };
    case ActionType.changeOpStrucuresFilterRegionalManagement:
      return {
        ...state,
        sales: remoteResourceInitialState([]),
        gcs: remoteResourceInitialState([]),
        gcsPaymentTypes: remoteResourceInitialState([]),
        opStructuresFilters: {
          ...state.opStructuresFilters,
          ...{ regionalMgmt: action.regionalMgmt },
        },
      };
    case ActionType.changeOpStrucuresFilterRegion:
      return {
        ...state,
        sales: remoteResourceInitialState([]),
        gcs: remoteResourceInitialState([]),
        gcsPaymentTypes: remoteResourceInitialState([]),
        opStructuresFilters: {
          ...state.opStructuresFilters,
          ...{ region: action.region },
        },
      };
    case ActionType.changeOpStrucuresFilterManagement:
      return {
        ...state,
        sales: remoteResourceInitialState([]),
        gcs: remoteResourceInitialState([]),
        gcsPaymentTypes: remoteResourceInitialState([]),
        opStructuresFilters: {
          ...state.opStructuresFilters,
          ...{ management: action.management },
        },
      };
    case ActionType.changeOpStrucuresFilterSupervision:
      return {
        ...state,
        sales: remoteResourceInitialState([]),
        gcs: remoteResourceInitialState([]),
        gcsPaymentTypes: remoteResourceInitialState([]),
        opStructuresFilters: {
          ...state.opStructuresFilters,
          ...{ supervision: action.supervision },
        },
      };
    case ActionType.changeOpStrucuresFilterCompany:
      return {
        ...state,
        sales: remoteResourceInitialState([]),
        gcs: remoteResourceInitialState([]),
        gcsPaymentTypes: remoteResourceInitialState([]),
        opStructuresFilters: {
          ...state.opStructuresFilters,
          ...{ company: action.company },
        },
      };
    case ActionType.findMissingClosingTotalRequested:
      return {
        ...state,
        missingClosingTotal: {
          ...state.missingClosingTotal,
          missingClosingTotal: remoteResourceLoading(null),
        },
      };
    case ActionType.findMissingClosingTotalSuccess:
      return {
        ...state,
        missingClosingTotal: {
          ...state.missingClosingTotal,
          missingClosingTotal: remoteResourceLoadSuccess(action.totals),
        },
      };
    case ActionType.findMissingClosingTotalFailure:
      return {
        ...state,
        missingClosingTotal: {
          ...state.missingClosingTotal,
          missingClosingTotal: remoteResourceLoadFailure(null, action.error),
        },
      };
    case ActionType.changeMissingClosingTotalFilteredStores:
      return {
        ...state,
        missingClosingTotal: {
          ...state.missingClosingTotal,
          filteredStores: action.stores,
        },
      };
    case ActionType.findReliefsRequested:
      return {
        ...state,
        reliefs: remoteResourceLoading([]),
      };
    case ActionType.findReliefsSuccess:
      return {
        ...state,
        reliefs: remoteResourceLoadSuccess(action.reliefs),
      };
    case ActionType.findReliefsFailure:
      return {
        ...state,
        reliefs: remoteResourceLoadFailure([], action.error),
      };
    case ActionType.findCancellationsRequested:
      return {
        ...state,
        cancellations: remoteResourceLoading([]),
      };
    case ActionType.findCancellationsSuccess:
      return {
        ...state,
        cancellations: remoteResourceLoadSuccess(action.cancellations),
      };
    case ActionType.findCancellationsFailure:
      return {
        ...state,
        cancellations: remoteResourceLoadFailure([], action.error),
      };
    case ActionType.findSalesByProductRequested:
      return {
        ...state,
        salesByProduct: remoteResourceLoading([]),
      };
    case ActionType.findSalesByProductSuccess:
      return {
        ...state,
        salesByProduct: remoteResourceLoadSuccess(action.salesByProduct),
      };
    case ActionType.findSalesByProductFailure:
      return {
        ...state,
        salesByProduct: remoteResourceLoadFailure([], action.error),
      };
    case ActionType.findGCsByProductRequested:
      return {
        ...state,
        gcsByProduct: remoteResourceLoading([]),
      };
    case ActionType.findGCsByProductSuccess:
      return {
        ...state,
        gcsByProduct: remoteResourceLoadSuccess(action.gcsByProduct),
      };
    case ActionType.findGCsByProductFailure:
      return {
        ...state,
        gcsByProduct: remoteResourceLoadFailure([], action.error),
      };
    case ActionType.findPettyCashDifferenceRequested:
      return {
        ...state,
        pettyCashDifference: remoteResourceLoading([]),
      };
    case ActionType.findPettyCashDifferenceSuccess:
      return {
        ...state,
        pettyCashDifference: remoteResourceLoadSuccess(action.pettyCashDifference),
      };
    case ActionType.findPettyCashDifferenceFailure:
      return {
        ...state,
        pettyCashDifference: remoteResourceLoadFailure([], action.error),
      };
    case ActionType.findPettyCashDifferenceByPaymentTypeRequested:
      return {
        ...state,
        PettyCashDifferenceByPaymentType: remoteResourceLoading([]),
      };
    case ActionType.findPettyCashDifferenceByPaymentTypeSuccess:
      return {
        ...state,
        PettyCashDifferenceByPaymentType: remoteResourceLoadSuccess(
          action.PettyCashDifferenceByPaymentType
        ),
      };
    case ActionType.findPettyCashDifferenceByPaymentTypeFailure:
      return {
        ...state,
        PettyCashDifferenceByPaymentType: remoteResourceLoadFailure([], action.error),
      };
    case ActionType.findSalesJournalRequested:
      return {
        ...state,
        salesJournal: {
          ...state.salesJournal,
          gcs: remoteResourceLoading([]),
          ncs: remoteResourceLoading([]),
          fcs: remoteResourceLoading([]),
          thirdparty: remoteResourceLoading([]),
        },
      };
    case ActionType.findSalesJournalGcsSuccess:
      return {
        ...state,
        salesJournal: {
          ...state.salesJournal,
          gcs: remoteResourceLoadSuccess(action.gcs),
        },
      };
    case ActionType.findSalesJournalGcsFailure:
      return {
        ...state,
        salesJournal: {
          ...state.salesJournal,
          gcs: remoteResourceLoadFailure([], action.error),
        },
      };
    case ActionType.findSalesJournalNcsSuccess:
      return {
        ...state,
        salesJournal: {
          ...state.salesJournal,
          ncs: remoteResourceLoadSuccess(action.ncs),
        },
      };
    case ActionType.findSalesJournalNcsFailure:
      return {
        ...state,
        salesJournal: {
          ...state.salesJournal,
          ncs: remoteResourceLoadFailure([], action.error),
        },
      };
    case ActionType.findSalesJournalFcsSuccess:
      return {
        ...state,
        salesJournal: {
          ...state.salesJournal,
          fcs: remoteResourceLoadSuccess(action.fcs),
        },
      };
    case ActionType.findSalesJournalFcsFailure:
      return {
        ...state,
        salesJournal: {
          ...state.salesJournal,
          fcs: remoteResourceLoadFailure([], action.error),
        },
      };
    case ActionType.findSalesJournalThirdPartySuccess:
      return {
        ...state,
        salesJournal: {
          ...state.salesJournal,
          thirdparty: remoteResourceLoadSuccess(action.thirdparty),
        },
      };
    case ActionType.findSalesJournalThirdPartyFailure:
      return {
        ...state,
        salesJournal: {
          ...state.salesJournal,
          thirdparty: remoteResourceLoadFailure([], action.error),
        },
      };
    case ActionType.changeMasterImportFilterCountry:
      return {
        ...state,
        mastersImport: remoteResourceInitialState([]),
        mastersImportFilters: {
          ...state.mastersImportFilters,
          countries: action.countries,
        },
      };
    case ActionType.changeMasterImportFilterStartImportDate:
      return {
        ...state,
        mastersImport: remoteResourceInitialState([]),
        mastersImportFilters: {
          ...state.mastersImportFilters,
          startImportDate: getDateValue(action.date),
        },
      };
    case ActionType.changeMasterImportFilterEndImportDate:
      return {
        ...state,
        mastersImport: remoteResourceInitialState([]),
        mastersImportFilters: {
          ...state.mastersImportFilters,
          endImportDate: getDateValue(action.date),
        },
      };
    case ActionType.changeMasterImportFilterState:
      return {
        ...state,
        mastersImport: remoteResourceInitialState([]),
        mastersImportFilters: {
          ...state.mastersImportFilters,
          state: action.state,
        },
      };
    case ActionType.changeClosingFilterCountry:
      return {
        ...state,
        closingFilters: {
          ...state.closingFilters,
          countries: action.countries,
          stores: [],
          franchiseTypes: [],
          federativeEntities: [],
        },
        closing: {
          ...state.closing,
          sales: remoteResourceInitialState([]),
          collections: remoteResourceInitialState([]),
          current: [],
        },
      };
    case ActionType.changeClosingFilterStore:
      return {
        ...state,
        closingFilters: {
          ...state.closingFilters,
          stores: action.stores,
        },
        closing: {
          ...state.closing,
          sales: remoteResourceInitialState([]),
          collections: remoteResourceInitialState([]),
          current: [],
        },
      };
    case ActionType.changeClosingFilterStartBusinessDate:
      return {
        ...state,
        closingFilters: {
          ...state.closingFilters,
          startBusinessDate: getDateValue(action.date),
        },
        closing: {
          ...state.closing,
          sales: remoteResourceInitialState([]),
          collections: remoteResourceInitialState([]),
          current: [],
        },
      };
    case ActionType.changeClosingFilterEndBusinessDate:
      return {
        ...state,
        closingFilters: {
          ...state.closingFilters,
          endBusinessDate: getDateValue(action.date),
        },
        closing: {
          ...state.closing,
          sales: remoteResourceInitialState([]),
          collections: remoteResourceInitialState([]),
          current: [],
        },
      };
    case ActionType.changeClosingFilterViewMode:
      return {
        ...state,
        closingFilters: {
          ...state.closingFilters,
          viewMode: action.viewMode,
        },
        closing: {
          ...state.closing,
          sales: remoteResourceInitialState([]),
          collections: remoteResourceInitialState([]),
          current: [],
        },
      };
    case ActionType.changeClosingFilterFederativeEntities:
      return {
        ...state,
        closingFilters: {
          ...state.closingFilters,
          federativeEntities: action.federativeEntities,
        },
        closing: {
          ...state.closing,
          sales: remoteResourceInitialState([]),
          collections: remoteResourceInitialState([]),
          current: [],
        },
      };
    case ActionType.changeClosingFilterFranchiseTypes:
      return {
        ...state,
        closingFilters: {
          ...state.closingFilters,
          franchiseTypes: action.franchiseTypes,
        },
        closing: {
          ...state.closing,
          sales: remoteResourceInitialState([]),
          collections: remoteResourceInitialState([]),
          current: [],
        },
      };
    case ActionType.changeClosingFilterCauseTypes:
      return {
        ...state,
        closingFilters: {
          ...state.closingFilters,
          causeTypes: action.causeTypes,
        },
        closing: {
          ...state.closing,
          sales: remoteResourceInitialState([]),
          collections: remoteResourceInitialState([]),
          current: [],
        },
      };
    case ActionType.findMastersImportRequested:
      return {
        ...state,
        mastersImport: remoteResourceLoading([]),
      };
    case ActionType.findMastersImportSuccess:
      return {
        ...state,
        mastersImport: remoteResourceLoadSuccess(action.mastersImport),
      };
    case ActionType.findMastersImportFailure:
      return {
        ...state,
        mastersImport: remoteResourceLoadFailure([], action.error),
      };

    case ActionType.findMastersTypesRequested:
      return {
        ...state,
        mastersTypes: remoteResourceLoading([]),
      };
    case ActionType.findMastersTypesSuccess:
      return {
        ...state,
        mastersTypes: remoteResourceLoadSuccess(action.mastersTypes),
      };
    case ActionType.findMastersTypesFailure:
      return {
        ...state,
        mastersTypes: remoteResourceLoadFailure([], action.error),
      };

    case ActionType.mastersImportExecutionRequested:
      return {
        ...state,
        mastersImportExecution: remoteRequestLoading,
      };
    case ActionType.mastersImportExecutionSuccess:
      return {
        ...state,
        mastersImportExecution: remoteRequestSuccess,
      };
    case ActionType.mastersImportExecutionFailure:
      return {
        ...state,
        mastersImportExecution: remoteRequestFailure(action.error),
      };
    case ActionType.changeFilterAccountingEntriesFields:
      return {
        ...state,
        sales: remoteResourceInitialState([]),
        filters: {
          ...state.filters,
          accountingEntriesFields: !state.filters.accountingEntriesFields,
        },
      };
    case ActionType.changePendingInterfacesFilterViewMode:
      return {
        ...state,
        pendingInterfacesFilter: {
          ...state.pendingInterfacesFilter,
        },
        pendingInterfacesState: {
          ...state.pendingInterfacesState,
          sales: remoteResourceInitialState([]),
        },
      };
    case ActionType.changePendingInterfacesFilterCountry:
      return {
        ...state,
        pendingInterfacesFilter: {
          ...state.pendingInterfacesFilter,
          country: action.country,
          stores: [],
        },
        pendingInterfacesState: {
          ...state.pendingInterfacesState,
          sales: remoteResourceInitialState([]),
        },
      };
    case ActionType.changePendingInterfacesFilterStore:
      return {
        ...state,
        pendingInterfacesFilter: {
          ...state.pendingInterfacesFilter,
          stores: action.stores,
        },
        pendingInterfacesState: {
          ...state.pendingInterfacesState,
          sales: remoteResourceInitialState([]),
        },
      };
    case ActionType.changePendingInterfacesFilterEndBusinessDate:
      return {
        ...state,
        pendingInterfacesFilter: {
          ...state.pendingInterfacesFilter,
          endBusinessDate: getDateValue(action.date),
        },
        pendingInterfacesState: {
          ...state.pendingInterfacesState,
          sales: remoteResourceInitialState([]),
        },
      };
    case ActionType.changePendingInterfacesFilterStartBusinessDate:
      return {
        ...state,
        pendingInterfacesFilter: {
          ...state.pendingInterfacesFilter,
          startBusinessDate: getDateValue(action.date),
        },
        pendingInterfacesState: {
          ...state.pendingInterfacesState,
          sales: remoteResourceInitialState([]),
        },
      };
    case ActionType.findPendingInterfacesSalesSuccess:
      return {
        ...state,
        pendingInterfacesState: {
          ...state.pendingInterfacesState,
          ...{
            sales: remoteResourceLoadSuccess(action.pendingInterfaces),
          },
        },
      };
    case ActionType.findPendingInterfacesSalesFailure:
      return {
        ...state,
        pendingInterfacesState: {
          ...state.pendingInterfacesState,
          ...{ sales: remoteResourceLoadFailure([], action.error) },
        },
      };
    default:
      return state;
  }
};

export default reducer;
