import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import SimpleSelectFormInput, {
  SimpleSelectFormInputProps,
} from '../FlexSalesForm/SimpleSelectFormInput';
import { reportMastersTypesSelector } from '../../state';
import { SimpleSelectOptionFormatter, useArrayToSimpleSelectOption } from '..';

export type MasterTypesFormInputProps = Omit<SimpleSelectFormInputProps, 'options' | 'value'>;

const formatter: SimpleSelectOptionFormatter = {
  type: 'fromSankeCase',
  translationIdBase: '',
  sort: 'asc',
};

const MasterTypesFormInput = (props: MasterTypesFormInputProps) => {
  const { t } = useTranslation();
  const { data, loading } = useSelector(reportMastersTypesSelector);
  const options = useArrayToSimpleSelectOption(data || [], formatter);

  return (
    <SimpleSelectFormInput
      {...props}
      label={t('masters-import.execution.masters-types')}
      defaultValue=""
      options={options}
      loading={loading}
    />
  );
};

export default MasterTypesFormInput;
